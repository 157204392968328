import React, { useEffect, useState } from "react";
import { getKeycloakInstance, getRolesFromUserinfo } from "./keycloak";
import { GraphiQL } from "graphiql";
import { explorerPlugin } from "@graphiql/plugin-explorer";
import { jwtDecode } from "jwt-decode";
import { Modal } from "react-responsive-modal";
import { ToolbarButton } from "@graphiql/react";
import "@graphiql/plugin-explorer/dist/style.css";
import { fetchData } from "./fetchData";
import fetcher from "./fetcher";
import { buildSchema } from "graphql";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { getmeshUrl } from "./mesh";

function App() {
  const [token, setToken] = useState("");
  const [roles, setRoles] = useState("");
  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [response, setResponse] = useState();
  const [cdnResponse, setCdnResponse] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const tenant = queryParams.get("tenant") || "embracecloud"; // Get client_id from URL or fallback to default
  const query = queryParams.get("query") || ""; 
  const cdnUrl = process.env.REACT_APP_CDN_URL;
  const cdnKey = process.env.REACT_APP_CDN_KEY;

  const keycloak = getKeycloakInstance(tenant); // Use the dynamic clientId

  useEffect(() => {
    keycloak
      .init({
        onLoad: "login-required",
        scope: "identity-provider-user portal-user",
      })
      .then((authenticated) => {
        if (authenticated) {
          setToken(keycloak.token);
          getRolesFromUserinfo(tenant, keycloak.token)
            .then((roles) => {
              setRoles(roles);
            })
            .catch((exception) => {
              console.log(exception);
            });

          keycloak.onTokenExpired = () => {
            keycloak
              .updateToken(30)
              .then((refreshed) => {
                if (refreshed) {
                  setToken(keycloak.token);
                } else {
                  console.warn("Token refresh failed");
                }
              })
              .catch((error) =>
                console.error("Failed to refresh token:", error)
              );
          };
        } else {
          console.error("User not authenticated");
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const headers = {
          "X-Hive-CDN-Key": cdnKey,
        };

        const { data, status } = await fetchData(cdnUrl, headers);

        if (status === 200 && data) {
          setCdnResponse(buildSchema(data));
        }
      } catch (error) {
        console.error("Error fetching schema:", error);
      }
    };

    fetchSchema();
  }, [cdnUrl, cdnKey]);

  const fetcher = createGraphiQLFetcher({
    url: getmeshUrl(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleRoleClick = () => {
    setResponse("");
    setResponse(roles); // Set the custom response to be displayed
  };

  const explorer = explorerPlugin();

  return (
    <div style={{ minHeight: "100vh" }} className="container">
      {token ? (
        <GraphiQL
          response={response}
          schema={cdnResponse}
          fetcher={fetcher}
          plugins={[explorer]}
          query={query}
        >
          <GraphiQL.Logo>
            Tenant: {tenant} <br></br>
            User: {jwtDecode(token)["email"]} <br></br>
          </GraphiQL.Logo>

          <GraphiQL.Toolbar>
            <ToolbarButton onClick={handleRoleClick} label="My active roles">
              Roles
            </ToolbarButton>
          </GraphiQL.Toolbar>
        </GraphiQL>
      ) : (
        <div>Loading or not authenticated...</div>
      )}
    </div>
  );
}

export default App;
